.Timer {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 48px;
  justify-content: space-between;
  padding: 20px;
}

.Timer.paused {
  color: #505050;
}

.Timer .time {
  flex: 1;
}

.Timer .label {
  flex: 0;
}
