.ActiveTimersView {
  display: flex;
  flex-flow: column;
}

.ActiveTimersView .add {
  display: flex;
}

.ActiveTimersView .add button {
  background: #381c38;
  color: rgb(221, 199, 199);
  flex: 1;
  font-size: 48px;
  padding: 8px;
}

.ActiveTimersView .instructions {
  color: #454545;
  font-size: 12px;
  font-weight: 700;
  padding: 20px;
  text-transform: uppercase;
}

.ActiveTimersView .instructions ul {
  list-style-type: none;
  line-height: 1.4;
}
